import { Builder, withChildren } from '@builder.io/react'
import CoversSection from 'components/storybloks/photobooks/sections/CoversSection'
import PaymentSection from 'components/storybloks/photobooks/sections/PaymentSection'
import PricesSection from 'components/storybloks/photobooks/sections/PricesSection'
import ReviewsSection from 'components/storybloks/photobooks/sections/ReviewsSection'
import SellingPointsSection from 'components/storybloks/photobooks/sections/SellingPointsSection'
import SliderSection from 'components/storybloks/photobooks/sections/SliderSection'
import { Box, Button, Carousel, Container } from 'atomic'
import TipsSection from 'components/storybloks/photobooks/sections/TipsSection'
import { illustrationTypes } from 'atomic/components/atoms/illustrations/Illustration'
import { kluddIllustrationTypes } from 'atomic/components/atoms/illustrations/KluddIllustration'
import { ouTheme, themeVars } from 'atomic/styles/theme.css'
import FunFacts from 'components/builder_components/fun_facts/FunFacts'
import HeadingComponent from 'components/builder_components/heading/Heading'
import PreFooter from 'components/builder_components/pre_footer/PreFooter'
import PromoComponent from 'components/builder_components/promo_component/PromoComponent'
import Quote from 'components/builder_components/quote/Quote'
import TextImageComponent from 'components/builder_components/text_image_component/TextImageComponent'
import QuoteSection from 'components/storybloks/photobooks/sections/QuoteSection'
import JobOptions from 'components/builder_components/job_options/JobOptions'
import ContactForms from 'components/builder_components/contact_forms/ContactForms'
import HelpAboveTheFold from 'components/builder_components/help_above_the_fold/HelpAboveTheFold'
import PaymentMethods from 'components/builder_components/payment_methods/PaymentMethods'
import DeliverySection from 'components/builder_components/delivery_section/DeliverySection'
import HelpContainer from 'components/builder_components/help_container/HelpContainer'
import CustomizableContainer from 'components/builder_components/customizable_container/CustomizableContainer'
import { arrowIllustrationTypes } from 'atomic/components/atoms/illustrations/ArrowIllustration'
import StatisticsContainer from 'components/builder_components/campaign_components/statistics_container/StatisticsContainer'
import ContractSection from 'components/builder_components/campaign_components/contract_section/ContractSection'
import LogosComponent from 'components/builder_components/logos_component/LogosComponent'
import Kundo from 'components/builder_components/kundo/Kundo'
import HeroBlog from 'components/builder_components/hero_blog/HeroBlog'
import ButtonBox from 'components/builder_components/button_box/ButtonBox'
import ListBlog from 'components/builder_components/list_blog/ListBlog'
import ImagesComponent from 'components/builder_components/images_component/ImagesComponent'
import Illustration from 'components/builder_components/illustration/Illustration'
import TextComponent from 'components/builder_components/text_component/TextComponent'
import HeroProduct from 'components/hero_product/HeroProduct'
import HeroGeneric from 'components/builder_components/hero_generic/HeroGeneric'

const allowedImageFileTypes = ['jpeg', 'png', 'jpg', 'gif', 'webp']
const allowedVideoFileTypes = ['mp4', 'mov', 'webm']

export default function registerBuilderComponents(): void {
  const ouColors = ouTheme.colors

  const marginAndPaddingInputs = [
    { name: 'margin', type: 'string', enum: [...Object.keys(themeVars.spaces), 'auto'], advanced: true },
    { name: 'marginBottom', type: 'string', enum: [...Object.keys(themeVars.spaces), 'auto'], defaulValue: 'xxxl' },
    { name: 'marginTop', type: 'string', enum: [...Object.keys(themeVars.spaces), 'auto'], advanced: true },
    { name: 'marginLeft', type: 'string', enum: [...Object.keys(themeVars.spaces), 'auto'], advanced: true },
    { name: 'marginRight', type: 'string', enum: [...Object.keys(themeVars.spaces), 'auto'], advanced: true },
    { name: 'padding', type: 'string', enum: [...Object.keys(themeVars.spaces)], advanced: true },
    { name: 'paddingLeft', type: 'string', enum: [...Object.keys(themeVars.spaces)], advanced: true },
    { name: 'paddingRight', type: 'string', enum: [...Object.keys(themeVars.spaces)], advanced: true },
    { name: 'paddingTop', type: 'string', enum: [...Object.keys(themeVars.spaces)], advanced: true },
    { name: 'paddingBottom', type: 'string', enum: [...Object.keys(themeVars.spaces)], advanced: true },
  ]

  const textInputs = [
    { name: 'fontSize', type: 'string', enum: [...Object.keys(themeVars.fontSizes)], advanced: true },
    { name: 'textAlign', type: 'string', enum: ['left', 'center', 'right'], advanced: true },
    { name: 'color', type: 'string', enum: [...Object.keys(ouColors)], advanced: true },
  ]

  const layoutInputs = [...marginAndPaddingInputs, ...textInputs]

  const imageSubFields = [
    { name: 'src', type: 'file', allowedFileTypes: allowedImageFileTypes },
    { name: 'objectFit', type: 'string', enum: ['contain', 'cover'], defaultValue: 'cover' },
    { name: 'alt', type: 'string', defaultValue: 'Alt text' },
    { name: 'caption', type: 'string' },
    { name: 'fractionInGrid', type: 'string', enum: ['1fr', '2fr', '3fr'], defaultValue: '1fr' },
    { name: 'maxHeight', type: 'number', defaultValue: 0 },
  ]

  const arrowSubFields = [
    { name: 'arrowDirection', type: 'string', enum: ['up', 'down'], defaultValue: 'down' },
    {
      name: 'arrowRotation',
      friendlyName: 'Rotate arrow',
      helperText: 'Add the degrees you want the illustration to rotate by',
      type: 'range',
      defaultValue: 0,
      min: 0,
      max: 100,
    },
  ]

  const fontSettingsFields = [
    { name: 'family', type: 'string', enum: ['primary', 'accent'], defaultValue: 'accent' },
    { name: 'size', type: 'string', enum: [...Object.keys(ouTheme.fontSizes)], defaultValue: 'xl' },
    { name: 'weight', type: 'string', enum: [...Object.keys(ouTheme.fontWeights)], defaultValue: 'normal' },
    { name: 'align', type: 'string', enum: ['left', 'center', 'right'], defaultValue: 'center' },
  ]

  const productTypes = ['photo', 'print', 'wedding', 'travel', 'baby', 'yearbook', 'art']

  /*
   * =======================================
   * Components for ALL models
   * (blog-article, generic-page, page)
   * =======================================
   */
  Builder.registerComponent(HeadingComponent, {
    name: 'Heading',
    inputs: [
      ...marginAndPaddingInputs,
      {
        name: 'modelType',
        helperText: 'Choose here if this is a heading for a blog article or page',
        type: 'string',
        enum: ['blog', 'pages'],
        defaultValue: 'blog',
      },
      { name: 'text', type: 'text', helperText: 'This is where you write your heading' },
      { name: 'blogHeadingPosition', friendlyName: 'Text align', type: 'string', enum: ['left', 'center', 'right'] },
      { name: 'as', friendlyName: 'As tag', type: 'string', enum: ['span', 'p', 'h1', 'h2', 'h3', 'h4', 'h5'] },
      { name: 'fontWeight', type: 'string', enum: [...Object.keys(themeVars.fontWeights)], defaultValue: 'bold' },
      { name: 'fontSize', type: 'string', enum: [...Object.keys(themeVars.fontSizes)], defaultValue: 'xxl' },
      {
        name: 'anchorRef',
        friendlyName: 'Use as reference?',
        helperText: 'Toggle this if you want to add this heading as a reference for anchor links.',
        type: 'boolean',
        defaultValue: false,
        showIf: `model === 'blog-article'`,
      },
      {
        name: 'anchorRefName',
        friendlyName: 'Anchor Reference',
        type: 'string',
        showIf: `options.get('anchorRef') === true`,
        regex: { pattern: '^[a-z0-9-]+$', message: 'Value must be characters (a-z) or numbers and words are separated with hyphens (-)' },
      },
      {
        name: 'anchorLink',
        friendlyName: 'Use as anchor link?',
        helperText: 'Toggle this if you want to add anchor link to this heading (remember to also add the anchor reference to the right element).',
        type: 'boolean',
        defaultValue: false,
        showIf: `model === 'blog-article'`,
      },
      {
        name: 'anchorLinkName',
        friendlyName: 'Anchor Link',
        type: 'string',
        showIf: `options.get('anchorLink') === true`,
        regex: { pattern: '^[a-z0-9-]+$', message: 'Value must be characters (a-z) or numbers and words are separated with hyphens (-)' },
      },
    ],
  })

  Builder.registerComponent(ImagesComponent, {
    name: 'AwesomeImageComponent',
    friendlyName: 'Images',
    inputs: [
      ...marginAndPaddingInputs,
      { name: 'fullBleed', friendlyName: 'Fullbleed images', type: 'boolean', defaultValue: false },
      { name: 'showGutter', friendlyName: 'Show space between images', type: 'boolean', defaultValue: true },
      {
        name: 'globalBorderRadius',
        friendlyName: 'Border radius',
        helperText: 'This will give your image rounded corners, higher number equals more rounded corners',
        type: 'number',
        defaultValue: 8,
      },
      {
        name: 'images',
        friendlyName: 'Images',
        type: 'list',
        subFields: imageSubFields,
      },
    ],
  })

  Builder.registerComponent(Button, {
    name: 'Button',
    inputs: [
      ...layoutInputs,
      { name: 'variant', type: 'string', enum: [...Object.keys(ouTheme.buttonTypes)], defaultValue: 'primary' },
      { name: 'children', friendlyName: 'Label', helperText: 'This is where you write the text in your button', type: 'string' },
      { name: 'size', type: 'string', enum: ['l', 'm', 's'] },
      { name: 'blogButtonPosition', friendlyName: 'Button position', type: 'string', enum: ['left', 'center', 'right', 'none'] },
    ],
  })

  Builder.registerComponent(SliderSection, {
    name: 'SliderSection',
    friendlyName: 'Image Slider',
    inputs: [
      { name: 'marginBottom', friendlyName: 'Bottom margin', type: 'string', enum: [...Object.keys(themeVars.spaces)], defaultValue: 'xxxl' },
      {
        name: 'slides',
        type: 'list',
        subFields: [{ name: 'src', type: 'file', allowedFileTypes: allowedImageFileTypes }],
      },
    ],
  })

  Builder.registerComponent(Quote, {
    name: 'Quote',
    friendlyName: 'Quote',
    inputs: [
      ...marginAndPaddingInputs,
      {
        name: 'modelType',
        helperText: 'Choose here if this quote is for a blog article or page',
        type: 'string',
        enum: ['blog', 'pages'],
        defaultValue: 'blog',
      },
      { name: 'quote', type: 'text', defaultValue: 'Did we always have that much fun? Did we never fight? Was there always a party?' },
      { name: 'fontFamily', type: 'string', enum: ['primary', 'accent'], defaultValue: 'accent', showIf: `options.get('modelType') === 'blog'` },
      {
        name: 'fontSize',
        type: 'string',
        enum: [...Object.keys(ouTheme.fontSizes)],
        defaultValue: 'xl',
        showIf: `options.get('modelType') === 'blog'`,
      },
      { name: 'textAlign', type: 'string', enum: ['left', 'center', 'right'], defaultValue: 'left' },
      {
        name: 'lineHeight',
        type: 'string',
        enum: [...Object.keys(ouTheme.lineHeights)],
        defaultValue: 'l',
        showIf: `options.get('modelType') === 'blog'`,
      },
      {
        name: 'illustration',
        friendlyName: 'Image/Illustration',
        type: 'file',
        allowedFileTypes: allowedImageFileTypes,
        showIf: `options.get('modelType') === 'blog'`,
      },
      { name: 'illustrationPosition', type: 'string', enum: ['left', 'right'], defaultValue: 'left', showIf: `options.get('modelType') === 'blog'` },
      {
        name: 'fontWeight',
        type: 'string',
        enum: [...Object.keys(themeVars.fontWeights)],
        defaultValue: 'bold',
        showIf: `options.get('modelType') === 'pages'`,
      },
      { name: 'backgroundColor', type: 'string', enum: [...Object.keys(ouColors)], defaultValue: 'mossa4' },
      { name: 'signature', type: 'text', showIf: `options.get('modelType') === 'pages'` },
    ],
  })

  /*
   * =======================================
   * Components explicitly for Blog
   * (blog-article)
   * =======================================
   */

  Builder.registerComponent(HeroBlog, {
    name: 'StoriesAboveTheFold',
    friendlyName: 'Hero',
    models: ['blog-article'],
    inputs: [
      ...marginAndPaddingInputs,
      { name: 'fullBleedLayout', friendlyName: 'Use full bleed layout', type: 'boolean', required: false, defaultValue: false },
      { name: 'title', type: 'text', required: true },
      { name: 'intro', type: 'longText', required: true },
      { name: 'image', type: 'file', allowedFileTypes: allowedImageFileTypes, required: true },
      {
        name: 'imagePosition',
        type: 'string',
        enum: ['center', 'top', 'left', 'right', 'bottom', 'top left', 'top right', 'bottom left', 'bottom right'],
        required: false,
        defaultValue: 'center',
      },
      { name: 'videoCheck', friendlyName: 'Use video instead of image', type: 'boolean', required: false, defaultValue: false },
      {
        name: 'videoSettings',
        type: 'object',
        showIf: `options.get('videoCheck') === true`,
        subFields: [
          { name: 'videosrc', friendlyName: 'Video', type: 'file', allowedFileTypes: allowedVideoFileTypes, required: true },
          { name: 'loop', type: 'boolean' },
          { name: 'muted', type: 'boolean' },
          { name: 'fileType', type: 'string', enum: ['mp4', 'mov', 'webm'], defaultValue: 'mp4' },
        ],
      },
      { name: 'guestAuthor', friendlyName: 'Add Guest Author', type: 'boolean', required: false, defaultValue: false },
      {
        name: 'guestAuthorSettings',
        friendlyName: 'Fill in Guest author details',
        helperText: 'This information will only be shown for this story. It will not be saved for future use.',
        type: 'object',
        showIf: `options.get('guestAuthor') === true`,
        subFields: [
          { name: 'name', type: 'text', required: true },
          { name: 'email', type: 'text' },
          { name: 'photo', type: 'file', allowedFileTypes: allowedImageFileTypes },
        ],
      },
    ],
  })

  Builder.registerComponent(ButtonBox, {
    name: 'CtaButton',
    friendlyName: 'Button Box',
    models: ['blog-article'],
    inputs: [
      ...marginAndPaddingInputs,
      { name: 'link', type: 'string', defaultValue: '/create' },
      { name: 'text', type: 'string', defaultValue: 'Create your book' },
      { name: 'buttonText', type: 'string', defaultValue: 'Create' },
      { name: 'buttonType', type: 'string', enum: [...Object.keys(ouTheme.buttonTypes)], defaultValue: 'primary' },
      { name: 'image', type: 'file', allowedFileTypes: allowedImageFileTypes },
      { name: 'backgroundColor', type: 'string', enum: [...Object.keys(ouTheme.colors)], defaultValue: 'blåbär2' },
    ],
  })

  Builder.registerComponent(ListBlog, {
    name: 'Paragraphwithlist',
    friendlyName: 'List',
    models: ['blog-article'],
    inputs: [
      ...marginAndPaddingInputs,
      { name: 'dotColor', type: 'string', enum: [...Object.keys(ouColors)], defaultValue: 'blåbär2' },
      { name: 'startIndex', type: 'number', defaultValue: 1 },
      {
        name: 'list',
        type: 'list',
        subFields: [
          { name: 'title', type: 'text' },
          {
            name: 'anchorRef',
            friendlyName: 'Use as reference?',
            helperText: 'Toggle this if you want to add this heading as a reference for anchor links.',
            type: 'boolean',
            defaultValue: false,
            showIf: `model === 'blog-article'`,
          },
          {
            name: 'anchorRefName',
            friendlyName: 'Anchor Reference',
            type: 'string',
            showIf: `options.get('anchorRef') === true`,
            regex: { pattern: '^[a-z0-9-]+$', message: 'Value must be characters (a-z) or numbers and words are separated with hyphens (-)' },
          },
          {
            name: 'anchorLink',
            friendlyName: 'Use as anchor link?',
            helperText:
              'Toggle this if you want to add anchor link to this heading (remember to also add the anchor reference to the right element).',
            type: 'boolean',
            defaultValue: false,
            showIf: `model === 'blog-article'`,
          },
          {
            name: 'anchorLinkName',
            friendlyName: 'Anchor Link',
            type: 'string',
            showIf: `options.get('anchorLink') === true`,
            regex: { pattern: '^[a-z0-9-]+$', message: 'Value must be characters (a-z) or numbers and words are separated with hyphens (-)' },
          },
          { name: 'paragraph', type: 'richText' },
        ],
      },
    ],
  })

  /*
   * =======================================
   * Components explicitly for Product pages
   * (page)
   * =======================================
   */
  Builder.registerComponent(HeroProduct, {
    name: 'ProductHero',
    friendlyName: 'Hero',
    models: ['page'],
    inputs: [
      { name: 'marginBottom', friendlyName: 'Bottom margin', type: 'string', enum: [...Object.keys(themeVars.spaces)], defaultValue: 'xxxl' },
      { name: 'productType', type: 'string', enum: [...productTypes] },
      { name: 'title', type: 'text', defaultValue: '' },
      { name: 'subtitle', type: 'text', defaultValue: '' },
      { name: 'startCreateButtonText', friendlyName: 'Create button text', type: 'text' },

      {
        name: 'heroSlides',
        friendlyName: 'Hero images',
        type: 'list',
        defaultValue: [],
        subFields: [{ name: 'src', type: 'file', allowedFileTypes: allowedImageFileTypes }],
      },
      {
        name: 'infoList',
        friendlyName: 'Info list background color',
        helperText: 'This is for the list where we outline the book types, paper types, book sizes etc.',
        type: 'string',
        defaultValue: 'mossa4',
        enum: [...Object.keys(ouColors)],
      },
    ],
  })

  Builder.registerComponent(PricesSection, {
    name: 'PricesSection',
    friendlyName: 'Prices',
    models: ['page'],
    inputs: [
      { name: 'marginBottom', friendlyName: 'Bottom margin', type: 'string', enum: [...Object.keys(themeVars.spaces)], defaultValue: 'xxxl' },
      { name: 'productType', type: 'string', enum: ['book', 'print'], required: true, defaultValue: 'book' },
      { name: 'title', type: 'string', defaultValue: '' },
      { name: 'subtitle', type: 'string', defaultValue: '' },
      { name: 'customCoverImage', friendlyName: 'Add custom cover images', type: 'boolean', defaultValue: false },
      {
        name: 'book27x27cmHardCoverImage',
        friendlyName: 'Image (hardcover 27x27)',
        type: 'file',
        allowedFileTypes: allowedImageFileTypes,
        showIf: `options.get('customCoverImage') === true && options.get('productType') === 'book'`,
      },
      {
        name: 'book20x20cmHardCoverImage',
        friendlyName: 'Image (hardcover 20x20)',
        type: 'file',
        allowedFileTypes: allowedImageFileTypes,
        showIf: `options.get('customCoverImage') === true && options.get('productType') === 'book'`,
      },
      {
        name: 'book20x20cmSoftCoverImage',
        friendlyName: 'Image (softcover)',
        type: 'file',
        allowedFileTypes: allowedImageFileTypes,
        showIf: `options.get('customCoverImage') === true && options.get('productType') === 'book'`,
      },
      {
        name: 'semiGlossPaperImage',
        friendlyName: 'Image (semi-gloss paper)',
        type: 'file',
        allowedFileTypes: allowedImageFileTypes,
        showIf: `(options.get('customCoverImage') === true && options.get('productType') === 'print')`,
      },
      {
        name: 'mattePaperImage',
        friendlyName: 'Image (matte paper)',
        type: 'file',
        allowedFileTypes: allowedImageFileTypes,
        showIf: `options.get('customCoverImage') === true && options.get('productType') === 'print'`,
      },
      { name: 'cardColors', friendlyName: 'Change card colors', type: 'boolean', defaultValue: false },
      {
        name: 'largeHardCoverCardColor',
        friendlyName: 'Card color (hardcover 27x27)',
        type: 'string',
        enum: [...Object.keys(ouColors)],
        showIf: `options.get('cardColors') === true && options.get('productType') === 'book'`,
      },
      {
        name: 'mediumHardCoverCardColor',
        friendlyName: 'Card color (hardcover 20x20)',
        type: 'string',
        enum: [...Object.keys(ouColors)],
        showIf: `options.get('cardColors') === true && options.get('productType') === 'book'`,
      },
      {
        name: 'softCoverCardColor',
        friendlyName: 'Card color (softcover)',
        type: 'string',
        enum: [...Object.keys(ouColors)],
        showIf: `options.get('cardColors') === true && options.get('productType') === 'book'`,
      },
      {
        name: 'semiGlossCardColor',
        friendlyName: 'Card color (semi-gloss paper)',
        type: 'string',
        enum: [...Object.keys(ouColors)],
        showIf: `options.get('cardColors') === true && options.get('productType') === 'print'`,
      },
      {
        name: 'matteCardColor',
        friendlyName: 'Card color (matte paper)',
        type: 'string',
        enum: [...Object.keys(ouColors)],
        showIf: `options.get('cardColors') === true && options.get('productType') === 'print'`,
      },
    ],
  })

  Builder.registerComponent(CoversSection, {
    name: 'CoversSection',
    friendlyName: 'Covers',
    models: ['page'],
    inputs: [
      { name: 'marginBottom', friendlyName: 'Bottom margin', type: 'string', enum: [...Object.keys(themeVars.spaces)], defaultValue: 'xxxl' },
      { name: 'title', type: 'text', defaultValue: 'Cover section' },
      { name: 'buttonText', type: 'text', defaultValue: '' },
      { name: 'hardcoverUsp1', type: 'text', defaultValue: '' },
      { name: 'hardcoverUsp2', type: 'text', defaultValue: '' },
      { name: 'hardcoverUsp3', type: 'text', defaultValue: '' },
      { name: 'softcoverUsp1', type: 'text', defaultValue: '' },
      { name: 'softcoverUsp2', type: 'text', defaultValue: '' },
      { name: 'softcoverUsp3', type: 'text', defaultValue: '' },
      { name: 'customCoverImage', friendlyName: 'Add custom cover images', type: 'boolean', defaultValue: false },
      {
        name: 'hardCoverImage',
        friendlyName: 'Hard Cover image',
        helperText: 'Add custom cover image, leave empty for default image',
        type: 'file',
        allowedFileTypes: allowedImageFileTypes,
        showIf: `options.get('customCoverImage') === true`,
      },
      {
        name: 'softCoverImage',
        friendlyName: 'Soft Cover image',
        helperText: 'Add custom cover image, leave empty for default image',
        type: 'file',
        allowedFileTypes: allowedImageFileTypes,
        showIf: `options.get('customCoverImage') === true`,
      },
    ],
  })

  Builder.registerComponent(PaymentSection, {
    name: 'PaymentSection',
    friendlyName: 'Pre-footer',
    models: ['page'],
    inputs: [{ name: 'marginBottom', type: 'string', enum: [...Object.keys(themeVars.spaces)] }],
  })

  /*
   * =======================================
   * Components explicitly for Generic pages
   * (generic-page)
   * =======================================
   */
  Builder.registerComponent(HeroGeneric, {
    name: 'PageHero',
    friendlyName: 'Hero image',
    models: ['generic-page'],
    inputs: [
      { name: 'fullbleedLayout', friendlyName: 'Use full bleed layout', type: 'boolean', defaultValue: false },
      { name: 'marginBottom', friendlyName: 'Bottom margin', type: 'string', enum: [...Object.keys(themeVars.spaces)], defaultValue: 'xxxl' },
      { name: 'title', type: 'text', defaultValue: 'This is the heading', showIf: `options.get('fullbleedLayout') === false` },
      { name: 'useRichTextEditor', type: 'boolean', defaultValue: false },
      { name: 'richTextTitle', friendlyName: 'Title', type: 'richText', showIf: `options.get('useRichTextEditor') === true` },
      { name: 'uppercase', friendlyName: 'Make title uppercase', type: 'boolean', defaultValue: false },
      { name: 'fontWeight', type: 'string', enum: [...Object.keys(themeVars.fontWeights)], defaultValue: 'bold' },
      { name: 'titleSize', type: 'string', enum: [...Object.keys(themeVars.fontSizes)], defaultValue: 'xxl' },
      { name: 'intro', type: 'longText', defaultValue: 'This is the intro text', required: true },
      { name: 'introSize', type: 'string', enum: [...Object.keys(themeVars.fontSizes)], defaultValue: 'l' },
      { name: 'useButton', type: 'boolean', defaultValue: true },
      {
        name: 'button',
        type: 'object',
        subFields: [
          { name: 'text', type: 'text', defaultValue: 'This is the button text' },
          { name: 'type', type: 'string', enum: [...Object.keys(ouTheme.buttonTypes)], defaultValue: 'secondary' },
          { name: 'link', type: 'string', defaultValue: '/create' },
          { name: 'size', type: 'string', enum: ['l', 'm', 's'], defaultValue: 'm' },
        ],
        defaultValue: { text: 'This is the button text', type: 'secondary', link: '/create', size: 'm' },
        showIf: `options.get('useButton') === true`,
      },
      { name: 'image', type: 'file', allowedFileTypes: allowedImageFileTypes, required: true },
      { name: 'imagePosition', type: 'string', enum: ['center', 'top', 'bottom', 'left', 'right', '40% center'], defaultValue: 'center' },
      { name: 'quoteInImage', type: 'boolean', defaultValue: false },
      {
        name: 'quote',
        type: 'object',
        showIf: `options.get('quoteInImage') === true`,
        subFields: [
          { name: 'text', type: 'longText', defaultValue: 'This is the qoute' },
          { name: 'signature', type: 'text', defaultValue: 'Lina Andersson, CEO Once Upon' },
          { name: 'color', friendlyName: 'Text color', type: 'string', enum: [...Object.keys(ouColors)], defaultValue: 'white' },
        ],
      },
    ],
  })

  Builder.registerComponent(HelpAboveTheFold, {
    name: 'Above the fold',
    friendlyName: 'Hero illustration',
    models: ['generic-page'],
    inputs: [
      ...marginAndPaddingInputs,
      { name: 'illustration', type: 'string', enum: [...illustrationTypes], defaultValue: 'heart-hug' },
      { name: 'backgroundColor', type: 'string', enum: [...Object.keys(ouColors)], defaultValue: 'blåbär4' },
      { name: 'title', type: 'text', defaultValue: 'This is the heading' },
      { name: 'intro', type: 'longText', defaultValue: 'This is the intro text', showIf: `options.get('useRichTextEditor') === false` },
      { name: 'richTextIntro', defaultValue: 'This is the intro text', type: 'richText', showIf: `options.get('useRichTextEditor') === true` },
      { name: 'useRichTextEditor', type: 'boolean', defaultValue: false },
    ],
  })

  Builder.registerComponent(TextImageComponent, {
    name: 'TextImageComponent',
    friendlyName: 'Text & Image',
    models: ['generic-page'],
    inputs: [
      { name: 'marginBottom', friendlyName: 'Bottom margin', type: 'string', enum: [...Object.keys(themeVars.spaces)], defaultValue: 'xxxl' },
      {
        name: 'extraMarginBottom',
        friendlyName: 'Add extra space in bottom',
        helperText: 'Did the margin bottom not give enough space? Use this to add more',
        type: 'boolean',
        defaultValue: false,
      },
      {
        name: 'extraMarginBottomSize',
        type: 'string',
        enum: [...Object.keys(themeVars.spaces)],
        defaultValue: 'xl',
        showIf: `options.get('extraMarginBottom') === true`,
      },
      {
        name: 'fullbleedLayout',
        friendlyName: 'Use fullbleed layout',
        type: 'boolean',
        defaultValue: false,
      },
      { name: 'useFullbleedImage', type: 'boolean', defaultValue: false },
      {
        name: 'mirroredLayout',
        type: 'boolean',
        helperText: 'Toggle this if you want the image to appear on the left side and the text on the right side',
      },
      {
        name: 'columnLayout',
        type: 'boolean',
        helperText: 'Toggle this if you want the image to appear above the text',
        showIf: `options.get('useFullbleedImage) === false`,
      },
      {
        name: 'backgroundCheck',
        friendlyName: 'Add background color',
        helperText: 'Toggle this if you want a background color to the section',
        type: 'boolean',
        defaultValue: false,
      },
      {
        name: 'color',
        type: 'string',
        enum: [...Object.keys(ouColors)],
        defaultValue: 'lav4',
        showIf: `options.get('backgroundCheck') === true`,
      },
      { name: 'heading', type: 'text', defaultValue: 'This is the heading', required: true },
      { name: 'addHeadingLink', friendlyName: 'Add anchor link to heading', type: 'boolean', defaultValue: false },
      {
        name: 'headingLink',
        friendlyName: 'Anchor link',
        type: 'text',
        showIf: `options.get('addHeadingLink') === true`,
        regex: { pattern: '^[a-z0-9-]+$', message: 'Value must be characters (a-z) or numbers and words are separated with hyphens (-)' },
      },
      { name: 'ingress', type: 'longText', required: false },
      { name: 'addIllustration', type: 'boolean', defaultValue: false },
      { name: 'illustration', type: 'string', enum: [...illustrationTypes], showIf: `options.get('addIllustration') === true` },
      {
        name: 'textList',
        type: 'list',
        subFields: [
          { name: 'subheading', type: 'text' },
          { name: 'paragraph', type: 'longText', showIf: `options.get('useRichTextEditor') === false` },
          { name: 'richTextParagraph', friendlyName: 'Paragraph', type: 'richText', showIf: `options.get('useRichTextEditor') === true` },
          { name: 'useRichTextEditor', type: 'boolean', defaultValue: false },
          { name: 'showAsIngress', friendlyName: 'Bigger font size', type: 'boolean', defaultValue: false },
          { name: 'centerText', type: 'boolean', defaultValue: false },
        ],
        required: true,
      },
      { name: 'addList', helperText: 'Toggle this if you want to add a list item to your text section', type: 'boolean', defaultValue: false },
      {
        name: 'list',
        type: 'object',
        subFields: [
          { name: 'title', type: 'text' },
          { name: 'dotColor', type: 'string', enum: [...Object.keys(ouColors)], defaultValue: 'kantarell1' },
          { name: 'startIndex', type: 'number', defaultValue: 1 },
          {
            name: 'listItem',
            type: 'list',
            subFields: [{ name: 'text', type: 'richText' }],
          },
        ],
        showIf: `options.get('addList') === true`,
      },
      { name: 'addSlideshow', helperText: 'Use if you want more than one image', type: 'boolean', defaultValue: false },
      {
        name: 'slides',
        type: 'list',
        subFields: [
          { name: 'src', type: 'file', allowedFileTypes: allowedImageFileTypes },
          { name: 'roundedCorners', type: 'number' },
          { name: 'maxHeight', type: 'number' },
        ],
        showIf: `options.get('addSlideshow') === true`,
      },
      { name: 'paginatorColor', type: 'string', enum: ['light', 'dark'], defaultValue: 'dark', showIf: `options.get('addSlideshow') === true` },
      {
        name: 'paginatorPosition',
        type: 'string',
        enum: ['floating', 'bottom'],
        defaultValue: 'floating',
        showIf: `options.get('addSlideshow') === true`,
      },
      {
        name: 'image',
        type: 'object',
        showIf: `options.get('addSlideshow') === false`,
        subFields: [
          { name: 'src', friendlyName: 'Image file', type: 'file', allowedFileTypes: allowedImageFileTypes },
          { name: 'maxHeight', type: 'number', defaultValue: undefined, showIf: `options.get('useFullbleedImage') === false` },
          { name: 'roundedCorners', type: 'number', showIf: `options.get('useFullbleedImage') === false` },
          { name: 'floatingImage', type: 'boolean', defaultValue: false, hideFromUI: true },
          { name: 'top', type: 'number', showIf: `options.get('floatingImage') === true` },
          { name: 'bottom', type: 'number', showIf: `options.get('floatingImage') === true` },
          { name: 'left', type: 'number', showIf: `options.get('floatingImage') === true` },
          { name: 'right', type: 'number', showIf: `options.get('floatingImage') === true` },
        ],
      },
      { name: 'addButton', type: 'boolean', defaultValue: false },
      {
        name: 'button',
        type: 'object',
        defaultValue: { text: 'This is the button text', type: 'secondary', link: '/photobooks', size: 'm' },
        showIf: `options.get('addButton') === true`,
        subFields: [
          { name: 'text', type: 'text', defaultValue: 'This is the button text' },
          { name: 'type', type: 'string', enum: [...Object.keys(ouTheme.buttonTypes)], defaultValue: 'secondary' },
          { name: 'link', type: 'string', defaultValue: '/create' },
          { name: 'target', friendlyName: 'Open link in new window', type: 'boolean', defaultValue: false },
          { name: 'size', type: 'string', enum: ['l', 'm', 's'], defaultValue: 'm' },
        ],
      },
      {
        name: 'illustrationInImage',
        friendlyName: 'Add illustration',
        helperText: 'Choose your illustration version',
        type: 'string',
        hideFromUI: true,
        enum: ['version1', 'version2'],
      },
    ],
  })

  Builder.registerComponent(FunFacts, {
    name: 'FunFacts',
    friendlyName: 'Facts',
    models: ['generic-page'],
    inputs: [
      { name: 'marginBottom', type: 'string', enum: [...Object.keys(themeVars.spaces)], defaultValue: 'xxxl' },
      { name: 'backgroundColor', type: 'string', enum: [...Object.keys(ouColors)], defaultValue: 'hjortron4' },
      {
        name: 'funFact1',
        type: 'object',
        required: true,
        defaultValue: { illustration: 'chat', kluddColor: 'blåbär3', text: '' },
        subFields: [
          { name: 'illustration', type: 'string', enum: [...illustrationTypes] },
          { name: 'kluddType', type: 'string', enum: [...kluddIllustrationTypes] },
          { name: 'kluddColor', type: 'string', enum: [...Object.keys(ouColors)] },
          { name: 'kluddWidth', type: 'number' },
          { name: 'text', type: 'longText' },
        ],
      },
      {
        name: 'funFact2',
        type: 'object',
        required: true,
        defaultValue: { illustration: 'peace', kluddColor: 'kantarell2', text: '' },
        subFields: [
          { name: 'illustration', type: 'string', enum: [...illustrationTypes] },
          { name: 'kluddType', type: 'string', enum: [...kluddIllustrationTypes] },
          { name: 'kluddColor', type: 'string', enum: [...Object.keys(ouColors)] },
          { name: 'kluddWidth', type: 'number' },
          { name: 'text', type: 'longText' },
        ],
      },
      {
        name: 'funFact3',
        type: 'object',
        required: true,
        defaultValue: { illustration: 'stars-1', kluddColor: 'ljung2', text: '' },
        subFields: [
          { name: 'illustration', type: 'string', enum: [...illustrationTypes] },
          { name: 'kluddType', type: 'string', enum: [...kluddIllustrationTypes] },
          { name: 'kluddColor', type: 'string', enum: [...Object.keys(ouColors)] },
          { name: 'kluddWidth', type: 'number' },
          { name: 'text', type: 'longText' },
        ],
      },
    ],
  })

  Builder.registerComponent(PreFooter, {
    name: 'PreFooter',
    friendlyName: 'Pre-footer',
    models: ['generic-page'],
    inputs: [
      { name: 'backgroundColor', type: 'string', enum: [...Object.keys(ouColors)], defaultValue: 'hjortron2' },
      { name: 'illustration', type: 'string', enum: [...illustrationTypes], defaultValue: 'diamond' },
      { name: 'illustrationHeight', type: 'number' },
      { name: 'headline', type: 'text', defaultValue: 'This is the headline' },
      { name: 'text', type: 'longText' },
      {
        name: 'button',
        type: 'object',
        defaultValue: { text: 'This is the button text', type: 'secondary', link: '/about-us/work-with-us', size: 'm' },
        subFields: [
          { name: 'text', type: 'text', defaultValue: 'This is the button text' },
          { name: 'type', type: 'string', enum: [...Object.keys(ouTheme.buttonTypes)], defaultValue: 'secondary' },
          { name: 'link', type: 'string', defaultValue: '/create' },
          { name: 'target', friendlyName: 'Open link in new window', type: 'boolean', defaultValue: false },
          { name: 'size', type: 'string', enum: ['l', 'm', 's'], defaultValue: 'm' },
        ],
      },
    ],
  })

  Builder.registerComponent(ContactForms, {
    name: 'ContactForms',
    friendlyName: 'Contact forms',
    models: ['generic-page'],
    inputs: [
      { name: 'marginBottom', type: 'string', enum: [...Object.keys(themeVars.spaces)], defaultValue: 'xxxl' },
      {
        name: 'extraMarginBottom',
        friendlyName: 'Add extra space in bottom',
        helperText: 'Did the margin bottom not give enough space? Use this to add more',
        type: 'boolean',
        defaultValue: false,
      },
      {
        name: 'extraMarginBottomSize',
        type: 'string',
        enum: [...Object.keys(themeVars.spaces)],
        defaultValue: 'xl',
        showIf: `options.get('extraMarginBottom') === true`,
      },
      { name: 'useTitle', friendlyName: 'Add title', type: 'boolean', defaultValue: true },
      { name: 'title', type: 'text', showIf: `options.get('useTitle') === true` },
      { name: 'formType', type: 'string', enum: ['collaborate', 'support', 'feedback'] },
    ],
  })

  Builder.registerComponent(JobOptions, {
    name: 'JobOptions',
    friendlyName: 'Jobs',
    models: ['generic-page'],
    inputs: [
      { name: 'marginBottom', type: 'string', enum: [...Object.keys(themeVars.spaces)], defaultValue: 'xxxl' },
      { name: 'title', type: 'text' },
      { name: 'deadline', type: 'text' },
    ],
  })

  Builder.registerComponent(Kundo, {
    name: 'Kundo',
    friendlyName: 'Kundo',
    models: ['generic-page'],
    inputs: [...marginAndPaddingInputs],
  })

  // HIDDEN COMPONENTS
  // reason explained under respective component
  Builder.registerComponent(PaymentMethods, {
    name: 'PaymentMethods',
    friendlyName: 'Payments',
    hideFromInsertMenu: true, // only used in Help pages
    models: ['generic-page'],
    inputs: [
      ...marginAndPaddingInputs,
      { name: 'backgroundColor', type: 'string', enum: [...Object.keys(ouColors)], defaultValue: 'white' },
      { name: 'borderRadius', type: 'string', enum: [...Object.keys(themeVars.borderRadiuses)], defaultValue: 'm' },
      { name: 'heading', type: 'text' },
      {
        name: 'payOptions',
        type: 'list',
        subFields: [
          { name: 'title', type: 'text' },
          { name: 'description', type: 'longText' },
        ],
      },
    ],
  })

  Builder.registerComponent(DeliverySection, {
    name: 'DeliverySection',
    friendlyName: 'Delivery',
    hideFromInsertMenu: true, // only used in Help pages
    models: ['generic-page'],
    inputs: [...marginAndPaddingInputs, { name: 'heading', type: 'text' }],
  })

  Builder.registerComponent(withChildren(HelpContainer), {
    name: 'HelpContainer',
    friendlyName: 'Container',
    models: ['generic-page'],
    hideFromInsertMenu: true, // only used in Help pages
    inputs: [...layoutInputs, { name: 'background', type: 'string', enum: [...Object.keys(ouColors)] }],
  })

  Builder.registerComponent(ContractSection, {
    name: 'ContractSection',
    friendlyName: 'Contract section',
    models: ['generic-page'],
    hideFromInsertMenu: true, // only used in Mom's story campaign page
    inputs: [
      ...marginAndPaddingInputs,
      { name: 'backgroundImage', type: 'file', allowedFileTypes: allowedImageFileTypes },
      { name: 'hideBackgroundImageOnMobile', type: 'boolean', defaultValue: false },
      { name: 'image', type: 'file', allowedFileTypes: allowedImageFileTypes },
      { name: 'heading', type: 'text', defaultValue: 'This is the heading' },
      { name: 'addHeadingLink', friendlyName: 'Add anchor link to heading', type: 'boolean', defaultValue: false },
      {
        name: 'headingLink',
        friendlyName: 'Anchor link',
        type: 'text',
        showIf: `options.get('addHeadingLink') === true`,
        regex: { pattern: '^[a-z0-9-]+$', message: 'Value must be characters (a-z) or numbers and words are separated with hyphens (-)' },
      },
      { name: 'intro', type: 'richText' },
      {
        name: 'button',
        type: 'object',
        defaultValue: { text: 'This is the button text', type: 'secondary', link: '', size: 'l' },
        subFields: [
          { name: 'text', type: 'text', defaultValue: 'This is the button text' },
          { name: 'type', type: 'string', enum: [...Object.keys(ouTheme.buttonTypes)], defaultValue: 'secondary' },
          { name: 'link', type: 'string', defaultValue: '/create' },
          { name: 'target', friendlyName: 'Open link in new window', type: 'boolean', defaultValue: false },
          { name: 'size', type: 'string', enum: ['l', 'm', 's'], defaultValue: 'm' },
        ],
      },
      {
        name: 'handwrittenText',
        friendlyName: 'Handwritten text',
        type: 'object',
        defaultValue: { text: 'This is the handwritten text', fontFamily: 'accent', fontSize: 'xl', lineHeight: 'xs' },
        subFields: [
          { name: 'text', type: 'longText', defaultValue: 'This is the quote' },
          { name: 'fontFamily', type: 'string', enum: ['primary', 'accent'], defaultValue: 'accent' },
          { name: 'fontSize', type: 'string', enum: [...Object.keys(ouTheme.fontSizes)], defaultValue: 'xl' },
          { name: 'lineHeight', type: 'string', enum: [...Object.keys(ouTheme.lineHeights)], defaultValue: 'xs' },
        ],
      },
    ],
  })

  Builder.registerComponent(StatisticsContainer, {
    name: 'StatisticsContainer',
    models: ['generic-page'],
    hideFromInsertMenu: true, // only used in Mom's story campaign page
    inputs: [
      {
        name: 'statisticsSlides',
        type: 'list',
        subFields: [
          { name: 'src', type: 'file', allowedFileTypes: allowedImageFileTypes },
          { name: 'color', friendlyName: 'Background color', type: 'string', enum: [...Object.keys(ouColors)], defaultValue: 'kantarell3' },
          { name: 'title', type: 'text' },
          { name: 'text', type: 'longText' },
        ],
      },
      { name: 'heading', type: 'text' },
      { name: 'intro', type: 'longText' },
      { name: 'source', type: 'longText' },
    ],
  })

  Builder.registerComponent(LogosComponent, {
    name: 'LogosComponent',
    models: ['generic-page'],
    hideFromInsertMenu: true, // only used in Mom's story campaign page
    inputs: [
      ...marginAndPaddingInputs,
      { name: 'intro', type: 'longText' },
      { name: 'fontSettings', type: 'object', subFields: fontSettingsFields },
      {
        name: 'logos',
        type: 'list',
        subFields: [
          { name: 'src', type: 'file', allowedFileTypes: allowedImageFileTypes },
          { name: 'alt', type: 'text' },
          { name: 'maxHeight', type: 'number' },
          { name: 'link', type: 'text' },
        ],
      },
    ],
  })

  Builder.registerComponent(withChildren(CustomizableContainer), {
    name: 'CustomizableContainer',
    models: ['generic-page'],
    hideFromInsertMenu: true, // only used in Mom's story campaign page
    inputs: [
      ...marginAndPaddingInputs,
      { name: 'backgroundColor', type: 'string', enum: [...Object.keys(ouTheme.colors)], defaultValue: 'blåbär3' },
      { name: 'addWaves', type: 'boolean', defaultValue: true },
      {
        name: 'backgroundOverlay',
        type: 'object',
        subFields: [
          { name: 'image', type: 'file', allowedFileTypes: allowedImageFileTypes },
          {
            name: 'imagePosition',
            type: 'string',
            enum: ['center', 'top', 'left', 'right', 'bottom', 'top left', 'top right', 'bottom left', 'bottom right'],
            defaultValue: 'center',
          },
        ],
      },
      { name: 'direction', type: 'string', enum: ['row', 'column', 'row-reverse', 'column-reverse'], defaultValue: 'row' },
      { name: 'space', friendlyName: 'Space between columns', type: 'string', enum: [...Object.keys(themeVars.spaces)], defaultValue: 'xl' },
    ],
    defaultChildren: [
      {
        '@type': '@builder.io/sdk:Element',
        component: { name: 'Text', options: { text: 'I am child text block!' } },
      },
    ],
  })

  /*
   * =======================================
   * Components for multiple models
   * (generic-page, page, blog-articles)
   * =======================================
   */
  Builder.registerComponent(ReviewsSection, {
    name: 'ReviewsSection',
    friendlyName: 'Reviews',
    models: ['page', 'generic-page'],
    inputs: [{ name: 'marginBottom', type: 'string', enum: [...Object.keys(themeVars.spaces)], defaultValue: 'xxxl' }],
  })

  Builder.registerComponent(SellingPointsSection, {
    name: 'SellingPointsSection',
    friendlyName: 'USP',
    models: ['page', 'generic-page'],
    inputs: [
      { name: 'marginBottom', friendlyName: 'Bottom margin', type: 'string', enum: [...Object.keys(themeVars.spaces)], defaultValue: 'xxxl' },
      {
        name: 'usp1',
        friendlyName: 'Selling point 1',
        type: 'object',
        defaultValue: { color: 'lav4', title: '', body: '', kluddColor: 'mossa2', illustration: 'flower' },
        subFields: [
          { name: 'color', friendlyName: 'Background color', type: 'string', enum: [...Object.keys(ouColors)] },
          { name: 'title', type: 'text' },
          { name: 'body', type: 'longText' },
          { name: 'kluddType', type: 'string', enum: [...kluddIllustrationTypes] },
          { name: 'kluddColor', type: 'string', enum: [...Object.keys(ouColors)] },
          { name: 'kluddWidth', type: 'number' },
          { name: 'illustration', type: 'string', enum: [...illustrationTypes] },
        ],
      },
      {
        name: 'usp2',
        friendlyName: 'Selling point 2',
        type: 'object',
        defaultValue: { color: 'lav4', title: '', body: '', kluddColor: 'kantarell1', illustration: 'delivery' },
        subFields: [
          { name: 'color', friendlyName: 'Background color', type: 'string', enum: [...Object.keys(ouColors)] },
          { name: 'title', type: 'text' },
          { name: 'body', type: 'longText' },
          { name: 'kluddType', type: 'string', enum: [...kluddIllustrationTypes] },
          { name: 'kluddColor', type: 'string', enum: [...Object.keys(ouColors)] },
          { name: 'kluddWidth', type: 'number' },
          { name: 'illustration', type: 'string', enum: [...illustrationTypes] },
        ],
      },
      {
        name: 'usp3',
        friendlyName: 'Selling point 3',
        type: 'object',
        defaultValue: { color: 'lav4', title: '', body: '', kluddColor: 'ljung2', illustration: 'hearts' },
        subFields: [
          { name: 'color', friendlyName: 'Background color', type: 'string', enum: [...Object.keys(ouColors)] },
          { name: 'title', type: 'text' },
          { name: 'body', type: 'longText' },
          { name: 'kluddType', type: 'string', enum: [...kluddIllustrationTypes] },
          { name: 'kluddColor', type: 'string', enum: [...Object.keys(ouColors)] },
          { name: 'kluddWidth', type: 'number' },
          { name: 'illustration', type: 'string', enum: [...illustrationTypes] },
        ],
      },
      {
        name: 'usp4',
        friendlyName: 'Selling point 4',
        type: 'object',
        defaultValue: { color: 'lav4', title: '', body: '', kluddColor: 'blåbär2', illustration: 'book' },
        subFields: [
          { name: 'color', friendlyName: 'Background color', type: 'string', enum: [...Object.keys(ouColors)] },
          { name: 'title', type: 'text' },
          { name: 'body', type: 'longText' },
          { name: 'kluddType', type: 'string', enum: [...kluddIllustrationTypes] },
          { name: 'kluddColor', type: 'string', enum: [...Object.keys(ouColors)] },
          { name: 'kluddWidth', type: 'number' },
          { name: 'illustration', type: 'string', enum: [...illustrationTypes] },
        ],
      },
    ],
  })

  Builder.registerComponent(Illustration, {
    name: 'IllustrationSection',
    friendlyName: 'Illustration',
    models: ['generic-page', 'page'],
    inputs: [
      ...marginAndPaddingInputs,
      { name: 'useImage', friendlyName: 'Use image instead', type: 'boolean', defaultValue: false },
      {
        name: 'image',
        type: 'object',
        showIf: `options.get('useImage') === true`,
        subFields: [
          { name: 'src', type: 'file', allowedFileTypes: allowedImageFileTypes },
          { name: 'alt', type: 'text', helperText: 'Is used for SEO, UX, and accessibility' },
          { name: 'width', type: 'number', defaultValue: 100 },
          { name: 'height', type: 'number', defaultValue: 100 },
        ],
      },
      {
        name: 'illustration',
        type: 'string',
        enum: [...illustrationTypes, ...arrowIllustrationTypes, ...kluddIllustrationTypes],
        defaultValue: 'arrow-5',
        showIf: `options.get('useImage') === false`,
      },
      { name: 'illustrationColor', friendlyName: 'Color', type: 'string', enum: [...Object.keys(ouColors)], defaultValue: 'malm0' },
      { name: 'illustrationPosition', friendlyName: 'Position', type: 'string', enum: ['left', 'right', 'center'], defaultValue: 'left' },
      { name: 'illustrationSize', friendlyName: 'Size', type: 'string', enum: ['s', 'm', 'l'], defaultValue: 'm' },
      { name: 'arrowOptions', type: 'object', subFields: arrowSubFields, showIf: `options.get('illustration').includes('arrow')` },
    ],
  })

  Builder.registerComponent(withChildren(Container), {
    name: 'OUContainer',
    models: ['page', 'generic-page'],
    friendlyName: 'Container (Utopia)',
    inputs: [...layoutInputs, { name: 'background', type: 'string', enum: [...Object.keys(ouColors)] }],
  })

  Builder.registerComponent(withChildren(Box), {
    name: 'OUBox',
    models: ['page', 'generic-page'],
    friendlyName: 'Box (Utopia)',
    inputs: [
      ...layoutInputs,
      { name: 'background', type: 'string', enum: [...Object.keys(ouColors)] },
      { name: 'display', type: 'string', enum: ['block', 'flex'], defaultValue: 'flex' },
      { name: 'flexDirection', type: 'string', enum: ['row', 'column', 'row-reverse', 'column-reverse'], defaultValue: 'row' },
      { name: 'gap', type: 'string', enum: [...Object.keys(themeVars.spaces)], defaultValue: 'm' },
      { name: 'maxWidth', type: 'number' },
    ],
  })

  Builder.registerComponent(withChildren(Carousel), {
    name: 'Carousel',
    models: ['page', 'generic-page'],
    inputs: [
      ...layoutInputs,
      { name: 'paginatorPosition', type: 'string', enum: ['floating', 'bottom'], defaultValue: 'bottom' },
      { name: 'paginatorColor', type: 'string', enum: ['dark', 'light'], defaultValue: 'dark' },
    ],
    defaultChildren: [
      {
        '@type': '@builder.io/sdk:Element',
        component: { name: 'Text', options: { text: 'I am child text block!' } },
      },
      {
        '@type': '@builder.io/sdk:Element',
        component: { name: 'Text', options: { text: 'I am child text block!' } },
      },
    ],
  })

  Builder.registerComponent(TextComponent, {
    name: 'AwesomeParagraphs',
    friendlyName: 'Text',
    models: ['blog-article', 'generic-page'],
    inputs: [
      ...marginAndPaddingInputs,
      {
        name: 'modelType',
        helperText: 'Choose here if this is a text for a blog article or page',
        type: 'string',
        enum: ['blog', 'pages'],
        defaultValue: 'blog',
      },
      { name: 'dualColumns', type: 'boolean', defaultValue: false },
      {
        name: 'isPreamble',
        friendlyName: 'Display as ingress',
        helperText: 'This option makes the text bigger and with more line height',
        type: 'boolean',
        defaultValue: false,
      },
      { name: 'headline', type: 'text', helperText: 'This is where you write your headline' },
      { name: 'headlineVariant', friendlyName: 'Type of headline', type: 'string', enum: ['heading', 'subheading'], defaultValue: 'heading' },
      { name: 'headlinePosition', type: 'string', enum: ['left', 'center', 'right'] },
      {
        name: 'anchorRef',
        friendlyName: 'Use as reference?',
        helperText: 'Toggle this if you want to add this heading as a reference for anchor links.',
        type: 'boolean',
        defaultValue: false,
      },
      {
        name: 'anchorRefName',
        friendlyName: 'Anchor Reference',
        type: 'string',
        showIf: `options.get('anchorRef') === true`,
        regex: { pattern: '^[a-z0-9-]+$', message: 'Value must be characters (a-z) or numbers and words are separated with hyphens (-)' },
      },
      {
        name: 'anchorLink',
        friendlyName: 'Use as anchor link?',
        helperText: 'Toggle this if you want to add anchor link to this heading (remember to also add the anchor reference to the right element).',
        type: 'boolean',
        defaultValue: false,
      },
      {
        name: 'anchorLinkName',
        friendlyName: 'Anchor Link',
        type: 'string',
        showIf: `options.get('anchorLink') === true`,
        regex: { pattern: '^[a-z0-9-]+$', message: 'Value must be characters (a-z) or numbers and words are separated with hyphens (-)' },
      },
      { name: 'paragraphContent', type: 'richText' },
      { name: 'bodyTextAlign', friendlyName: 'Text align', type: 'string', enum: ['left', 'center', 'right'] },
    ],
  })

  // HIDDEN COMPONENTS
  // reason explained under respective component
  Builder.registerComponent(TipsSection, {
    name: 'TipsSection',
    models: ['page', 'generic-page'],
    friendlyName: 'Blog puff 1',
    hideFromInsertMenu: true, // name is not unique enough, used as template instead
    inputs: [
      { name: 'marginBottom', friendlyName: 'Bottom margin', type: 'string', enum: [...Object.keys(themeVars.spaces)], defaultValue: 'xxxl' },
      { name: 'backgroundColor', type: 'string', enum: [...Object.keys(ouColors)], defaultValue: 'kantarell4' },
      { name: 'title', type: 'longText', defaultValue: '' },
      { name: 'circleText', type: 'text', defaultValue: 'News' },
      { name: 'description', type: 'longText', defaultValue: '' },
      { name: 'linkHref', type: 'url', defaultValue: '/weddingbooks' },
      { name: 'linkText', type: 'text', defaultValue: '' },
      { name: 'smallImage', type: 'file', allowedFileTypes: allowedImageFileTypes },
      { name: 'coverImage', type: 'file', allowedFileTypes: allowedImageFileTypes },
      { name: 'illustration', type: 'string', enum: [...illustrationTypes] },
      { name: 'illustrationBoxColor', type: 'string', enum: [...Object.keys(ouColors)] },
      { name: 'illustrationBoxText', type: 'longText', defaultValue: '' },
    ],
  })

  Builder.registerComponent(PromoComponent, {
    name: 'PromoComponent',
    friendlyName: 'Blog puff 2',
    hideFromInsertMenu: true, // name is not unique enough, used as template instead
    models: ['generic-page', 'page'],
    inputs: [
      { name: 'marginBottom', type: 'string', enum: [...Object.keys(themeVars.spaces)], defaultValue: 'xxxl' },
      {
        name: 'extraMarginBottom',
        friendlyName: 'Add extra space in bottom',
        helperText: 'Did the margin bottom not give enough space? Use this to add more',
        type: 'boolean',
        defaultValue: false,
      },
      {
        name: 'extraMarginBottomSize',
        type: 'string',
        enum: [...Object.keys(themeVars.spaces)],
        defaultValue: 'xl',
        showIf: `options.get('extraMarginBottom') === true`,
      },
      { name: 'backgroundColor', type: 'string', enum: [...Object.keys(ouColors)], defaultValue: 'lav3' },
      {
        name: 'mirroredLayout',
        helperText: 'Toggle this if you want the image to appear on the right side and the text on the left side',
        type: 'boolean',
        defaultValue: false,
      },
      { name: 'videoCheck', friendlyName: 'Use video instead of image', type: 'boolean', defaultValue: false },
      {
        name: 'image',
        type: 'object',
        showIf: `options.get('videoCheck') === false`,
        subFields: [
          { name: 'file', type: 'file', allowedFileTypes: allowedImageFileTypes },
          { name: 'imagePosition', type: 'string', enum: ['center', 'top', 'bottom', 'left', 'right'], required: false, defaultValue: 'center' },
          { name: 'imageAltText', type: 'text', helperText: 'Is used for SEO, UX, and accessibility' },
        ],
      },
      {
        name: 'video',
        type: 'object',
        showIf: `options.get('videoCheck') === true`,
        subFields: [
          { name: 'file', type: 'file', allowedFileTypes: allowedVideoFileTypes },
          { name: 'posterImage', type: 'file', allowedFileTypes: allowedImageFileTypes },
          { name: 'loop', type: 'boolean' },
          { name: 'muted', type: 'boolean' },
          { name: 'fileType', type: 'string', enum: ['mp4', 'mov', 'webm'], defaultValue: 'mp4' },
        ],
      },
      { name: 'subHeadline', type: 'text', hideFromUI: true },
      {
        name: 'subTitle',
        type: 'object',
        subFields: [
          { name: 'text', type: 'text' },
          { name: 'fontFamily', type: 'string', enum: ['primary', 'accent'], defaultValue: 'primary' },
          { name: 'fontSize', type: 'string', enum: [...Object.keys(themeVars.fontSizes)], defaultValue: 'base' },
          { name: 'fontWeight', type: 'string', enum: [...Object.keys(themeVars.fontWeights)], defaultValue: 'light' },
          { name: 'addUnderline', type: 'boolean', defaultValue: false },
        ],
      },
      { name: 'headline', type: 'text' },
      { name: 'text', type: 'longText' },
      { name: 'addList', type: 'boolean', defaultValue: false },
      {
        name: 'list',
        type: 'object',
        subFields: [
          { name: 'dotColor', type: 'string', enum: [...Object.keys(ouColors)], defaultValue: 'blåbär2' },
          { name: 'startIndex', type: 'number', defaultValue: 1 },
          {
            name: 'listItem',
            type: 'list',
            subFields: [
              { name: 'title', type: 'text' },
              { name: 'text', type: 'longText' },
            ],
          },
        ],
        showIf: `options.get('addList') === true`,
      },
      { name: 'useButton', type: 'boolean', defaultValue: true },
      {
        name: 'button',
        type: 'object',
        subFields: [
          { name: 'text', type: 'text', defaultValue: 'This is the button text' },
          { name: 'type', type: 'string', enum: [...Object.keys(ouTheme.buttonTypes)], defaultValue: 'secondary' },
          { name: 'link', type: 'string', defaultValue: '/create' },
          { name: 'size', type: 'string', enum: ['l', 'm', 's'], defaultValue: 'm' },
        ],
        defaultValue: { text: 'This is the button text', type: 'secondary', link: '/create', size: 'm' },
        showIf: `options.get('useButton') === true`,
      },
    ],
  })

  Builder.registerComponent(QuoteSection, {
    name: 'QuoteSection',
    friendlyName: 'Quote',
    hideFromInsertMenu: true, // not in use anymore, use component Quote instead. Needs to be kept for backwards compatibility
    models: ['page', 'generic-page'],
    inputs: [
      ...layoutInputs,
      { name: 'backgroundColor', type: 'string', enum: [...Object.keys(ouColors)], defaultValue: 'transparent' },
      { name: 'quote', type: 'longText' },
      { name: 'fontWeight', type: 'string', enum: [...Object.keys(themeVars.fontWeights)], defaultValue: 'bold' },
      { name: 'textAlign', type: 'string', enum: ['left', 'center', 'right'], defaultValue: 'center' },
      { name: 'signature', type: 'text' },
    ],
  })
}
